/* eslint-disable camelcase */
import _ from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import * as events from 'constants/events'
import storage from 'constants/storage'
import * as cookie from 'libs/cookie'
import * as environment from 'libs/environment'
import * as logging from 'libs/logging'
import platformLib from 'libs/platform'
import * as segment from 'libs/segment'
import * as userApiRequest from 'libs/user-api-request'

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
    fbq: any
    gtag: any
  }
}

export default class Analytics {
  user

  constructor(user: any) {
    this.user = user
  }

  static init(user: any) {
    return new Analytics(user)
  }

  setUser(user: any) {
    this.user = user
  }

  markPageVisited = (pageName: string, properties = {} as Record<string, string>) => {
    properties.platformType = 'web'
    properties.userDevice = platformLib.getUserDevice()
    this._persistEventToDb(pageName, properties)
    segment.page(pageName, this._scrubPIIFromProperties(properties))
    return this
  }

  trackEvent = async (event: string, trackProperties: any = {}, identifyProperties: any = {}) => {
    trackProperties.platformType = 'web'
    trackProperties.userDevice = platformLib.getUserDevice()
    const gclid = identifyProperties.gclid || cookie.getCookie(storage.G_CLID)
    const utmSource = identifyProperties.utm_source || cookie.getCookie(storage.UTM_SOURCE)
    const fromGoogleAd = !!gclid || utmSource === 'google'
    let fbEventProps = {}
    if (
      !fromGoogleAd &&
      (events.FACEBOOK_STANDARD_EVENTS.includes(event) ||
        events.FACEBOOK_CUSTOM_EVENTS.includes(event))
    ) {
      fbEventProps = {
        client_user_agent: navigator?.userAgent,
        eventId: uuidv4(),
        fbp: `fb.1.${Date.now()}.${uuidv4()}`,
        homeKey: cookie.getCookie(storage.HOME_KEY),
      }
    }

    try {
      await this._persistEventToDb(
        event,
        _.merge(trackProperties, identifyProperties, fbEventProps)
      )
    } catch (error) {
      console.error('Analytics: Persist event to db failed:', error)
    }

    try {
      await this._sendDataToSegment(event, trackProperties, identifyProperties)
    } catch (error) {
      console.error('Analytics: Sending event to mixpanel failed:', error)
    }

    try {
      if (
        !fromGoogleAd &&
        (events.FACEBOOK_STANDARD_EVENTS.includes(event) ||
          events.FACEBOOK_CUSTOM_EVENTS.includes(event)) &&
        typeof window !== 'undefined' &&
        window.fbq != null
      ) {
        // https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/customer-information-parameters
        fbEventProps = _.merge(
          this.getPropertiesForTrackEvents(
            event,
            this._scrubPIIFromProperties({
              ...trackProperties,
              ...fbEventProps,
            })
          )
        )

        // hotfix - started sending 5/7 and FB flagged as potentially violating personal data
        delete fbEventProps.firstNameDifferent
        delete fbEventProps.lastNameDifferent

        window.fbq(
          'track',
          this._convertToFacebookEventName(event),
          {
            extern_id: fbEventProps.external_id, // todo - trying...
            ...fbEventProps,
          },
          {
            eventID: fbEventProps.eventId,
            external_id: fbEventProps.external_id, // todo - trying...
          }
        )
      }
    } catch (error) {
      console.error('Analytics: Sending event to facebook/meta failed:', error)
    }

    return this
  }

  _convertToFacebookEventName = (eventName: string) => {
    switch (eventName) {
      case events.SIGN_UP: {
        return 'Lead'
      }
      case events.ONBOARDING_COMPLETE: {
        return 'CompleteRegistration'
      }
      case events.PURCHASE: {
        return 'Purchase'
      }
      case events.ADD_PAYMENT_INFO: {
        return 'AddPaymentInfo'
      }
      case events.ENTERPRISE_CONFIRM_TOS: {
        return 'HQL'
      }
      default: {
        return eventName
      } // Return the original event name if no mapping is found
    }
  }

  _scrubPIIFromProperties = (properties: any) => {
    if (properties == null) return properties
    const cleanProperties = _.cloneDeep(properties)
    delete cleanProperties.firstName
    delete cleanProperties.lastName
    delete cleanProperties.email
    delete cleanProperties.dateOfBirth
    delete cleanProperties.phoneNumber
    delete cleanProperties.age
    return _.omitBy(cleanProperties, _.isNil) // filter out null properties
  }

  _sendDataToSegment = async (event: string, trackProperties: any, identifyProperties: any) => {
    await this.handleIdentity(event, this._scrubPIIFromProperties(identifyProperties))
    const updatedTrackProperties = this.getPropertiesForTrackEvents(
      event,
      this._scrubPIIFromProperties(trackProperties)
    )
    segment.track(event, updatedTrackProperties)
  }

  _persistEventToDb = async (event: string, properties = {}) => {
    const userRef = this.user || {}
    const { id, email, firstName, lastName, phoneNumber, age, gender, userType } = userRef
    const eventData = {
      eventName: _.camelCase(event),
      userId: id,
      email,
      firstName,
      lastName,
      phoneNumber,
      age,
      gender,
      userType,
    }
    const dataToSave = _.merge(properties, eventData)
    await userApiRequest.saveEvents(dataToSave)
  }

  cleanUserData = (user: any) => {
    if (!user) {
      return
    }

    const {
      allTimeClassesWatched,
      currentExerciseProgram,
      dateLastClassWatched,
      enterpriseMember,
      id,
      loginType,
      smsConsent,
      emailConsent,
      stripePlanName,
      userType,
      isTestUser,
    } = user
    return {
      allTimeClassesWatched,
      currentExerciseProgram,
      dateLastClassWatched,
      enterpriseName: enterpriseMember?.enterprise.internalId,
      environment: environment.isProduction ? 'prod' : 'dev',
      id,
      loginType,
      smsConsent,
      emailConsent,
      stripePlanName,
      userType,
      isTestUser,
    }
  }

  /**
   * DEFAULT VALUES
   */
  getPropertiesForTrackEvents = (event: string, properties: any = {}) => {
    properties = properties || {}
    properties.environment = environment.isProduction ? 'prod' : 'dev'

    // important note: we map these segment events to FB Standard events in a setting in segment
    // (see here https://app.segment.com/agebold/destinations/facebook-pixel/sources/staging/instances/603945ed6a95fddc469119ae/configuration)
    // and here https://developers.facebook.com/docs/facebook-pixel/reference
    switch (event) {
      case events.ONBOARDING_COMPLETE:
      case events.SIGN_UP: {
        return _.merge(properties, {
          [events.INTEGRATION.facebook.parameters.CURRENCY]: 'USD',
          [events.INTEGRATION.facebook.parameters.VALUE]: 1.0,
        })
      }
      case events.PURCHASE: {
        return _.merge(properties, {
          [events.INTEGRATION.facebook.parameters.CURRENCY]: 'USD',
          [events.INTEGRATION.facebook.parameters.VALUE]: 1.0,
          [events.INTEGRATION.facebook.parameters.CONTENT_TYPE]: 'product',
          [events.INTEGRATION.facebook.parameters.ITEM_IDS]: properties.selectedStripePlanId,
        })
      }
      default: {
        return properties
      }
    }
  }

  /**
   * EVENTS THAT IMPACT IDENTITY:
   * Drip only data is for TAG related info (remove_tags is not supported by Segment-drip integration)
   */
  handleIdentity = async (event: string, properties: any) => {
    const user = this.user

    /**
     * IDENTIFY REGARDLESS OF USER PROPERTIES
     * Signing up, logging in and logging out need to fire identify whether or not we have properties to add
     */
    let userData = _.merge(this.cleanUserData(user), properties)
    userData = _.omitBy(userData, _.isNil) // filter out null properties
    switch (event) {
      case events.SIGN_IN:
      case events.SIGN_UP:
      case events.OPEN_LESSON: {
        // make sure we're matching the subsequent lesson events to the user
        segment.identify(userData)
        break
      }
      case events.SIGN_OUT: {
        this.user = null
        segment.reset()
        return
      }
      default: {
        // otherwise only fire identify if properties are added
        if (properties && !_.isEmpty(properties)) {
          segment.identify(userData)
        }
        break
      }
    }
  }
}

// https://developers.facebook.com/docs/marketing-api/conversions-api/parameters/fbp-and-fbc
export const generateFbc = (fbclid: string | null) => {
  if (_.isNil(fbclid)) {
    logging.toDatadog('generateFbc: undefined fbclid')
    return null
  }
  const fbc = `fb.1.${Date.now()}.${fbclid}`
  return fbc
}
